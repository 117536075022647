import _Draggable from "./Draggable";
var exports = {};
var _require = _Draggable,
    Draggable = _require.default,
    DraggableCore = _require.DraggableCore; // Previous versions of this lib exported <Draggable> as the root export. As to no-// them, or TypeScript, we export *both* as the root and as 'default'.
// See https://github.com/mzabriskie/react-draggable/pull/254
// and https://github.com/mzabriskie/react-draggable/issues/266

exports = Draggable;
exports.default = Draggable;
exports.DraggableCore = DraggableCore;
export default exports;
const _DraggableCore = exports.DraggableCore;
export { _DraggableCore as DraggableCore };