var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = log;
/*eslint no-console:0*/

function log() {
  var _console;

  if (undefined) (_console = console).log.apply(_console, arguments);
}

export default exports;